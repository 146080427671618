import React from 'react';
import { useSelector } from 'react-redux';

import { useBi, useTranslation } from '@wix/yoshi-flow-editor';

import { paidPostSubscribeClick } from '@wix/bi-logger-groups/v2';

import type { IFeedItem } from 'api/feed/types';

import { COMPONENT_ID } from 'common/utils';
import { useController } from 'common/context/controller';
import { selectGroupSlugById } from 'store/selectors';

import { EmptyState } from 'wui/EmptyState';
import { Button } from 'wui/Button';

import { GROUPS_APP_DEFINITION_ID } from '../../../../constants';

import { PricingPlanDetails } from './PricingPlanDetails';

import { getAriaId } from '../a11y';

interface IProps {
  item: IFeedItem;
  isSuggested?: boolean;
}

export function FeedItemPreview({ item, isSuggested }: IProps) {
  const { t } = useTranslation();
  const bi = useBi();

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;
  const plans = item.accessRestriction?.paidPlansOptions?.plans || [];

  const slug = useSelector(selectGroupSlugById(groupId));

  const { pricingPlans$ } = useController();

  return (
    <EmptyState
      variant="section"
      id={getAriaId(feedItemId, 'preview')}
      title={t('groups-web.discussion.feed.paid-post.preview.title')}
      subtitle={<PricingPlanDetails plan={plans[0]} />}
      action={
        plans.length ? (
          <Button onClick={navigateToPackagePicker}>
            {t('groups-web.discussion.feed.paid-post.preview.cta')}
          </Button>
        ) : undefined
      }
    />
  );

  function navigateToPackagePicker() {
    bi.report(
      paidPostSubscribeClick({
        group_id: groupId,
        post_id: feedItemId,
        post_details: JSON.stringify({
          type: isSuggested ? 'suggested' : 'default',
          photo: item.mediaData?.imageCount,
          video: item.mediaData?.videoCount,
          document: item.mediaData?.fileCount,
        }),
      }),
    );

    pricingPlans$.navigateToPackagePicker({
      planIds: item.accessRestriction?.paidPlansOptions?.planIds,
      biOptions: {
        referralInfo: 'groups_paid_post',
        referralId: feedItemId,
      },
      checkout: {
        successStatus: {
          content: {
            title: t('groups-web.restriction.paid-post.success.title'),
            message: t('groups-web.restriction.paid-post.success.body'),
            cta: t('groups-web.restriction.paid-post.success.cta'),
          },
          navigation: {
            type: 'section',
            options: {
              appDefinitionId: GROUPS_APP_DEFINITION_ID,
              sectionId: COMPONENT_ID.GROUP,
              state: `${slug}/discussion/${feedItemId}`,
            },
          },
        },
      },
    });
  }
}

FeedItemPreview.displayName = 'FeedItemPreview';
